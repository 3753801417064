<template lang="pug">
    .main-wrapper.unidades-salvar
        ProgressBar(v-if='waiting' mode="indeterminate")
        div(v-else)
            header.main-wrapper-header
                .p-grid.p-align-center
                    .p-col-3
                        Button.p-button-secondary.btn-back(icon='jam jam-chevrons-left' @click='$router.go(-1)')
                    .p-col-9.ta-right
                        h1.text-header.text-secondary MedClub /
                            <router-link :to='{ name: "area-atuacao" }'> Subgrupo</router-link> /
                            <b>{{  model.id ? 'Editar' : 'Adicionar' }}</b>

            form(@submit.prevent='handleSubmit()')
                ProgressBar(v-if='waitingForm' mode="indeterminate")
                div(v-else)
                    Panel(:header="`${ model.id ? 'Editar' : 'Adicionar' } Subgrupo`")
                        .p-grid.p-fluid.p-align-end(style='margin: 0 auto; max-width: 500px;')

                            .p-col-12(:class="{ 'form-group--error': submitted && $v.model.ds_area_atuacao.$error }")
                                label.form-label Descrição:
                                InputText(type='text' v-model='$v.model.ds_area_atuacao.$model')
                                .feedback--errors(v-if='submitted && $v.model.ds_area_atuacao.$error')
                                    .form-message--error(v-if="!$v.model.ds_area_atuacao.minLength") <b>Subgrupo</b> deve ter pelo menos 2 caracteres.
                                    .form-message--error(v-if="!$v.model.ds_area_atuacao.required") <b>Subgrupo</b> é obrigatório.

                            .p-col-12.mt-2.mb-2(v-if="model.id")
                                .p-grid
                                    .p-col-12.p-md-6
                                        span.d-block.o-hidden <b>Usuário criação: </b> {{ model.nm_usuario_cri }}
                                    .p-col-12.p-md-6
                                        span <b>Data da criação: </b> {{ model.dt_criado_f }}
                                    .p-col-12.p-md-6
                                        span.d-block.o-hidden <b>Usuário edição: </b> {{ model.nm_usuario_edi }}
                                    .p-col-12.p-md-6
                                        span <b>Data da atualização: </b> {{ model.dt_atualizado_f }}

                            .p-col-12
                                Button(label='Salvar' type="submit")

                
</template>

<style lang="scss">
    .unidades-salvar {
        .aux-1 { text-align: center; @media all and (max-width: 576px) { text-align: left; } }
        .p-inputtext[disabled=disabled] {
            color: #000;
            font-weight: 600;
            background-color: #ddd;
        }
        .p-picklist-source-controls,
        .p-picklist-target-controls {
            display: none;
        }
        .p-picklist-source-wrapper,
        .p-picklist-target-wrapper {
            width: 45%;
            @media all and (max-width: 576px) {
                width: 100%;
            }
        }
        .p-picklist-buttons-cell {
            text-align: center;
            .p-button {
                display: none !important;
                &:first-of-type, &:nth-of-type(3) {
                    display: inline-block !important;
                }
            }
        }
        .text-box {
            margin: 0;
            font-size: 16px;
        }
        .input-sexo .p-button {
            width: 50% !important;
        }
    }
</style>

<script>
    import ProgressBar from 'primevue/progressbar'
    import Panel from 'primevue/panel'
    import InputText from 'primevue/inputtext'
    import InputMask from 'primevue/inputmask'
    import Password from 'primevue/password'
    import Button from 'primevue/button'
    import Dropdown from 'primevue/dropdown'
    import SelectButton from 'primevue/selectbutton'
    import Tooltip from 'primevue/tooltip'

    import { AreaAtuacao } from './../../middleware'
    import { required, minLength } from 'vuelidate/lib/validators'
    // import moment from "moment"

    export default {
        components: {
            ProgressBar, Panel, InputText, Button, Password,
                InputMask, SelectButton, Dropdown, Tooltip
        },
        directives: { tooltip: Tooltip },
        created () {
            let id = 0
            if (! isNaN(this.$route.params.id)) id = parseInt(this.$route.params.id)
            if (id != 0) {
                AreaAtuacao.find(id).then(response => {
                    if (response.status == 200) {
                        let keys = Object.keys(this.model)
                        
                        keys.forEach(key => this.model[key] = response.data[key])
                    }
                    this.waiting = false
                })
            } else this.waiting = false          
        },
        data () {
            return {
                model: {
                    ds_area_atuacao: '',
                },
                waiting: true,
                waitingForm: false,
                submitted: false
            }
        },
        validations () {
            let v = {
                model: {
                    ds_area_atuacao: { required, minLength: minLength(2) },
                }
            }
            
            return v
        },
        methods: {
            handleSubmit () {
                this.submitted = true
                
                this.$v.$touch()
                if (this.$v.$invalid) return 0

                let dataSend = Object.assign({}, this.model)
                
                this.waitingForm = true                
                AreaAtuacao.save(dataSend).then(response => {
                    if (([200, 201]).includes(response.status)) {
                        if (! dataSend.id) this.$router.push({ name: 'area-atuacao' })
                        this.$toast.success('Cadastro salvo com sucesso', { duration: 3000 })
                    } else {
                        if (response.data.non_field_errors)
                            response.data.non_field_errors.forEach(errorMsg => {
                                this.$toast.error(errorMsg, { duration: 3000 })
                            })
                    }
                    this.waitingForm = false
                })
                
            }
         }
      }
</script>